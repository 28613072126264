.image-slider {
  position: relative;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image {
  width: 100%;
  height: 400px;
  border-radius: 16px;
  max-width: 1840px;
}
.left {
  transform: rotate(180deg);
  position: relative;
  width: 48px;
  height: 48px;
  font-size: 24px;
  color: white;
  z-index: 5;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  right: 20px;
  opacity: 0.7;
}
.right {
  position: relative;
  width: 48px;
  height: 48px;
  font-size: 24px;
  left: 20px;
  z-index: 5;
  cursor: pointer;
  user-select: none;
  opacity: 0.7;
}
.currentSlide {
  transition-duration: 0.5s ease;
  opacity: 0;
}
.currentSlide.active {
  opacity: 1;
  transition-duration: 0.5s;
  transform: scale(1.2);
}
h2 {text-align: center;}

.left:hover {
  opacity: 1;
  right: 25px;
  transition-duration: 0.5s;
}
.right:hover {
  opacity: 1;
  left: 25px;
  transition-duration: 0.5s;
}
.directions {
  padding-top: 30px;
}
footer {
  text-align: center;
  color: #111c24;
  font-size: 40px;
  padding-bottom: 20px;
  font-weight: 400;
  line-height: 1;
}
.directionTab {
  display: flex;
  justify-content: center;
  gap: 50px;
  color: #333;
    font-size: 18px;
}
body {
  font-family: KievitPro, Helvetica, Roboto, Arial, sans-serif;
}
.button {
  display: inline-block;
  vertical-align: top;
  padding: 8px 30px;
  border: 3px solid #4b710d;
  font-size: 14px;
  font-weight: 700;
  color: #4b710d;
  text-transform: uppercase;
  text-decoration: none;
  margin: 0 auto;
}


.main-logo {
  background: url(../content/fon.jpg) center no-repeat;
  height: calc(100vh - 199px);
}

.main_suptitle {
  font-family: 'Roboto', serif;
  font-size: calc(10px + 8vmin);
  color: #616161;
  margin-bottom: 20px;
}

.main_title {
  font-family: 'Roboto', serif;
  color: #4b710d;
  text-shadow: 1px 1px 2px black, 0 0 1em #333;
  font-size: calc(10px + 16vmin);
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
}
.main_title:after {
  content: "";
    display: block;
    width: 60px;
    height: 3px;
    margin: 60px auto 60px;
    background-color: #4b710d;
}
.main-btn {
  display: inline-block;
    vertical-align: top;
    padding: 8px 30px;
    border: 3px solid #4b710d;
    font-size: 14px;
    font-weight: 700;
    color: #4b710d;
    text-transform: uppercase;
    text-decoration: none;
    transition: .1s linear, color .1s linear;
    margin-top: 20px;
    &:hover {
      color: #fff;
      background-color: #4b710d;
    }
}


.main-text-block {
  margin: 0 auto;
  width: fit-content;
  position: relative;
  top: var(--top-position-general, 20vh);
  text-align: center;
  &_mobile {
    top: 10vh!important;
  }
}

.table_contacts {
  margin: 0 auto;
  
}
.contact_title {
  color: #111c24;
    font-size: 40px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    margin-top: 30px;
}
.map {
  position: relative;
  top: 30px;
  width: fit-content;
  margin: 0 auto;
  text-align: center;
  display: var(--display-map-value, block);
}
.services_title {
  color: #111c24;
    font-size: 40px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    margin-top: 30px;
}