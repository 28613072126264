.header__fixed {
  position: fixed;
  background: #fff;
  z-index: 20;
  width: 100%;
  top: 0px;
}
.header__top {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 8px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  background: #fff;
  z-index: 16;
  opacity: 1;
  transition: all 0.5s ease 0s;
  outline: none !important;
  height: 70px;
  border-bottom: 1px solid #735c50;
}
.header__bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  font-size: 20px;
}
.adress, .tel__number, .time__work {
  display: flex;
  align-items: center;
  color: #000;
}

.header__center {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 8px;
  box-sizing: border-box;
  background: #fff;
  z-index: 16;
  opacity: 1;
  transition: all 0.5s ease 0s;
  outline: none !important;
  height: min-content;
  border-bottom: 1px solid #735c50;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.logo__img {
  width: 120px;
  height: auto;
}
.logo__title {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.6;
  color: #735c50;
  letter-spacing: 0.02em;
  margin-left: 15px;
  letter-spacing: 0.4px;
  font-family: "Montserrat";
}
.register__meet {
  background: #99A77D;
    border-radius: 80px;
    color: #fff;
    padding: 9px 32px 10px 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    border: 1px solid #99A77D;
    text-align: center;
    position: relative;
    min-height: 48px;
    transition: all .5s;
    text-decoration: none;
    cursor: pointer;
}
a {
  color: #333;
  text-decoration: unset;
}


.menu__kebab {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 20px;
  height: 20px;
}

.register__meet__mobile {
  background: #99A77D;
    color: #fff;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    border: 1px solid #99A77D;
    text-align: center;
    position: relative;
    min-height: 70px;
    transition: all .5s;
    text-decoration: none;
    cursor: pointer;
}

.menu__kebab__list {
  position: absolute;
  left: 0;
  top: 199px;
  z-index: 20;
  height: calc(100vh - 199px);
  display: flex;
  padding-top: 20px;

  gap: 30px;
  width: 100vw;
  background-color: #fff;
  color: #fff !important;
  flex-direction: column;
  align-items: center;
}

#navLink {
  height: 30px;
  font-size: 18px;
  border-bottom: #99A77D solid 1px;
  width: 100vw;
  text-align: center;
}

.icons {
  width: 20px;
  height: 20px;
  margin-right: 13px;
}

.active {
  font-weight: 600;
  border-bottom: 3px solid #4b710d;
}