.header_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  font-size: 14px;
  margin: 0 auto;
  text-transform: uppercase;
  padding-top: 40px;
  font-weight: 700;
  color: #333;
  text-decoration: none;
  transition: color .1s linear;
}

.title_main {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: center;
  gap: 20px;
  min-height: 80vh;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.text {
  font-size: 30px;
  text-align: center;
}
.text:after {
  content: "";
  display: block;
  width: 60px;
  height: 3px;
  margin: 60px auto 60px;
  background-color: #4b710d;
}
#sup_title {
  font-family: 'Roboto', serif;
  font-size: 72px;
  color: #616161;
  margin-bottom: 20px;
}

#title {
  font-family: 'Roboto', serif;
  color: #4b710d;
  text-shadow: 1px 1px 2px black, 0 0 1em #333;
  font-size: 150px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
}
#btn_set {
  display: inline-block;
  vertical-align: top;
  padding: 8px 30px;
  border: 3px solid #4b710d;
  font-size: 14px;
  font-weight: 700;
  color: #4b710d;
  text-transform: uppercase;
  text-decoration: none;
  transition: background .1s linear, color .1s linear;
}
#btn_set:hover {
  background-color:#4b710d;
  color: white;
}
#services {
  color: #333;
  font-family: KievitPro, Helvetica, Roboto, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.4;
  margin: 0 auto;
  margin-top: 70px;
  border-collapse: collapse;
  text-indent: initial;
  border-spacing: 2px;
  font-size: 18px;
  margin-bottom: 40px;
  max-width: calc(100% - 20px);
}
.title_about {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
}
.text_about {
  margin-top: 20px;
  max-width: 800px;
  text-align: center;
  margin: 20px auto;
  width: 100%;
}
td {
  display: table-cell;
  padding: 10px 0;
  
}
tr {
  border-bottom: 2px solid #e76969;
}

.copyright {
  padding: 20px 0;
  font-size: 14px;
  border-top: 1px solid #735c50;
  text-align: center;
  height: 20px;
  margin-top: 20px;
  position: relative;
  width: 100%;
  bottom: 0;
}


.title_about {
  color: #111c24;
  font-size: 40px;
  font-weight: 400;
  line-height: 1;
}
.text_about {
  font-size: 20px;
  line-height: 32px;
  max-width: 90vw;
}
li {
  text-align: start;
}
.aboutUs {
  margin-top: 30px;
}